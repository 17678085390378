import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  img: {
    width: "62%",
    // margin: "30px 0 0px 0",
    alignSelf: "center",
    objectFit: "contain",
  },
});
